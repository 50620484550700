<template>
  <div class="sectionContainer">
    <div class="centeredInnerSection">
      <MonthlyOutputChart></MonthlyOutputChart>
    </div>
  </div>
</template>

<script>
import MonthlyOutputChart from "@/components/MonthlyOutputChart.vue";

export default {
  name: "OutputPrediction",
  components: { MonthlyOutputChart },
};
</script>

<style scoped>
.innerSection {
  flex-basis: 25%;
}
.chartText {
  max-width: 950px;
  margin: 10px auto;
}

.centeredInnerSection {
  margin-top: 2%;
}

.chartWrapper {
  width: 750px;
}
</style>
